import { propOr, equals } from "ramda";
import { ServiceFetcherCreate } from "@prima-afp/common-libs/dist/libs/http";
import AppSession from "./AppSession";
import handleAcquireTokenSilent from "../../ActiveDirectoryAuth/utils/handleAcquireTokenSilent";
import msalInstance from "../constants/msalInstance";

const ServiceFetcherDmentes = ServiceFetcherCreate({
  activeDirectoryAuth: true,
  baseUrl: process.env.REACT_APP_API_URL_DMENTES_PAC,
  accessTokenGetter: () => propOr("", "accessToken", AppSession.get()),
  onLogout: (error) => {
    AppSession.destroy(false);
    console.trace(error);
  },
  onError: (error, dataError) => {
    console.trace(error);
    if (equals(dataError.status, 401)) handleAcquireTokenSilent(msalInstance);
  },
});

export default ServiceFetcherDmentes;
