import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Switch, Route } from "react-router-dom";
import { isNil, map, not, or, prop } from "ramda";
import MainDashboardLayout from "../layouts/MainDashboardLayout";
import useRoles from '../../shared/hooks/useRoles';
import PopupRolesNotAvailable from "../components/PopupRolesNotAvailable";
import AppLoader from "../../ActiveDirectoryAuth/components/AppLoader";
import SharedModule from '../../shared';
import handleCloseSessionAD from '../../ActiveDirectoryAuth/utils/handleCloseSessionAD'
import handleSessionData from '../../ActiveDirectoryAuth/utils/handleSessionData';

const { PacEventBus } = SharedModule.libs;

const LayoutComponent = (props) => {
  const { module, config } = props;
  const { useGlobalAppContext }  = config;
  const { msalInstance, AppSession, exitSession, setExitSession, hasRolesInStorage, setHasRolesInStorage } = useGlobalAppContext();
  const { roles: rolesResponse, isError: isErrorRoles, isLoading: isRolesLoading } = useRoles();
  let roles = null;

  if (not(hasRolesInStorage) && not(isNil(rolesResponse))) {
    roles = rolesResponse;
    handleSessionData('roles', rolesResponse, AppSession, exitSession);
    setHasRolesInStorage(true);
  }

  if (hasRolesInStorage && isNil(rolesResponse)) {
    roles = AppSession.getRoles();
  }

  const handleCloseSession = () => handleCloseSessionAD(msalInstance, AppSession, setExitSession);

  useEffect(() => {
    PacEventBus.subscribe("close-session", handleCloseSession);
    return () => {
      PacEventBus.unsubscribe("close-session");
    };
  }, []);

  const showPopupRoles = or(isRolesLoading, hasRolesInStorage)
    ? false
    : or(isNil(roles), not(isNil(isErrorRoles)));

  const addConfig = (_config) => ({
    ..._config,
    useGlobalAppContext
  });

  return (
    <>
      { hasRolesInStorage ?
        <MainDashboardLayout navItems={ module.navigationItems(roles) } config={addConfig({})}>
          <module.componentRouter roles={roles} />
        </MainDashboardLayout>
        : <AppLoader />
      }
      {
        not(exitSession) &&
        <PopupRolesNotAvailable show={showPopupRoles} onConfirm={handleCloseSession}/>
      }
    </>
  );
};

const PrivateRouter = (props) => {

  const { privateModules = [], config = {} } = props;

  return (
    <Switch>
      <Redirect exact from="/" to="/inicio" />
      {map(
        (module) => (
          <Route
            path={prop("pathBase", module)}
            key={prop("pathBase", module)}
            component={() => <LayoutComponent module={module} config={config} />}
          />
        ),
        privateModules
      )}
      <Redirect exact from="*" to="/inicio" />
    </Switch>
  );
};

PrivateRouter.propTypes = {
  privateModules: PropTypes.array,
  config: PropTypes.object,
};

export default PrivateRouter;
