import React from 'react';
import { UnauthenticatedTemplate } from "@azure/msal-react";

import { Typography, Button } from '@prima-afp/prima-ui-elements/dist/core';
import loginRedirect from '../utils/loginRedirect';

const LoginForm = (props) => {
  const { config } = props;
  const { useGlobalAppContext } = config;
  const { msalInstance, AppSession, exitSession } = useGlobalAppContext();

  return (
    <UnauthenticatedTemplate>
      <Typography className="tw-mb-1 !tw-font-calibri" variant="body">Bienvenido(a) a tu plataforma</Typography>
      <Typography variant="h2" className="tw-mb-2" color=" var(--orangeColorMain)">Inicio de sesión</Typography>
      <Button theme="orange" className="tw-w-full" onClick={() => {
        // authHandler
        loginRedirect(msalInstance, AppSession, exitSession)
      }}>Siguiente</Button>
    </UnauthenticatedTemplate>
  );
};

export default LoginForm;
