import styled from 'styled-components';

const LoginFormWrapper = styled.div`
  width: 370px;
  margin-right: 206px;
  padding: 49px 35px 57px;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background: white;
`;

export default LoginFormWrapper;
