import React, { useEffect } from 'react';
import { LateralMenu } from '@prima-afp/prima-business-elements/dist/businessElements/application';
import { always, has, head, ifElse, join, map, pipe, prop, split, toLower, toUpper } from 'ramda';
import SharedModule from 'modules/shared';
import handleCloseSessionAD from '../../ActiveDirectoryAuth/utils/handleCloseSessionAD';

const { useMe } = SharedModule.hooks;
const { PacEventBus } = SharedModule.libs;

const capitalize = (word) => toUpper(word.charAt(0)) + toLower(word).slice(1);
const capitalizePhrase = pipe(split(' '), map(capitalize), join(' '));
const getFirstName = pipe(split(' '), head);
const concatNames = (first, second) => (source) => `${first(source)} ${second(source)}`;

const HOME_NAVIGATION_ITEM = {
  path: '/inicio',
  pathLink: '/inicio',
  text: 'Inicio',
  icon: () => (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.91124 7.19362L1.9113 7.19367C1.96115 7.2436 2.02749 7.27181 2.0991 7.27308C2.09913 7.27308 2.09915 7.27308 2.09917 7.27308L1.91124 7.19362ZM1.91124 7.19362C1.8049 7.08728 1.80486 6.91386 1.91127 6.80739L6.80706 1.91178L6.80711 1.91172C6.85878 1.85999 6.92685 1.83178 7.00007 1.83178C7.07317 1.83178 7.14128 1.85997 7.19308 1.91178L7.284 1.82085L7.19308 1.91178L12.0873 6.80595L12.0873 6.80597L12.0892 6.80777L12.0903 6.80881C12.1952 6.91554 12.1946 7.0879 12.0889 7.19371C12.0371 7.24554 11.9689 7.2738 11.8958 7.2738H11.3633C11.0981 7.2738 10.8831 7.48876 10.8831 7.75398V11.7082C10.8831 11.9624 10.6761 12.1694 10.4217 12.1694H8.98663V9.82511C8.98663 9.04111 8.34902 8.4034 7.56491 8.4034H6.43522C5.65113 8.4034 5.01342 9.0411 5.01342 9.82511V12.1694H3.57831C3.32407 12.1694 3.11696 11.9624 3.11696 11.7082V7.75398C3.11696 7.48876 2.90199 7.2738 2.63677 7.2738H2.11743L1.91124 7.19362ZM12.7688 6.12912L12.7687 6.12903L12.7677 6.12811L7.87222 1.23277C7.87221 1.23276 7.8722 1.23275 7.8722 1.23275C7.63941 0.999867 7.32927 0.871416 7.00007 0.871416C6.67086 0.871416 6.36072 0.999878 6.12785 1.23275L1.23487 6.12564L1.23486 6.12565L1.22967 6.13095L1.22966 6.13094C0.751214 6.61215 0.752001 7.39262 1.23203 7.87265C1.45048 8.09119 1.73902 8.21812 2.04719 8.23225C2.06147 8.23352 2.07577 8.23416 2.09015 8.23416H2.15659V11.7082C2.15659 12.4922 2.79431 13.1298 3.57831 13.1298H5.4936C5.75884 13.1298 5.97378 12.9147 5.97378 12.6496V9.82511C5.97378 9.57083 6.18089 9.36376 6.43522 9.36376H7.56491C7.81922 9.36376 8.02626 9.57081 8.02626 9.82511V12.6496C8.02626 12.9147 8.24121 13.1298 8.50645 13.1298H10.4217C11.2058 13.1298 11.8435 12.4922 11.8435 11.7082V8.23416H11.8958C12.2249 8.23416 12.5351 8.10569 12.768 7.87274L12.768 7.87272C13.2485 7.392 13.2487 6.61014 12.7688 6.12912Z" fill="#FF4F00" stroke="#FF4F00" strokeWidth="0.257168" />
    </svg>
  ),
};

const DashboardLateralMenu = (props) => {
  const { navItems, children, config = {} } = props;
  const { useGlobalAppContext }  = config;
  const { msalInstance, AppSession, setExitSession } = useGlobalAppContext();
  const { advisor } = useMe();

  const handleCloseSession = () => handleCloseSessionAD(msalInstance, AppSession, setExitSession);

  useEffect(() => {
    PacEventBus.subscribe('close-session', handleCloseSession);
    return () => { PacEventBus.unsubscribe('close-session'); };
  }, []);

  const getSessionName = ifElse(
    has('names'),
    pipe(
      concatNames(
        pipe(prop('names'), getFirstName, capitalizePhrase),
        pipe(prop('lastnames'), capitalizePhrase),
      ),
    ),
    always(''),
  );

  return (
    <LateralMenu
      theme="dark"
      navItems={[HOME_NAVIGATION_ITEM, ...navItems]}
      userInformation={{
        title: getSessionName(advisor),
      }}
      closeSession={{
        onClick: handleCloseSession,
      }}
    >
      { children }
    </LateralMenu>
  );
};

export default DashboardLateralMenu;
