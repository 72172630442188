import React from 'react';
import HomeRouter from './router/HomeRouter';
import Home from './pages/home';
import ModuleConfigProvider from '../shared/providers/ModuleConfigProvider.jsx';

const components = {
  Home,
};

const defaultModuleConfig = { };

const HomeModule = ({ attachNavigation = [], config = {} } = {}) => ({
  pathBase: '/inicio',
  componentRouter: () => (
    <ModuleConfigProvider.ModuleConfigProvider config={config} defaultConfig={defaultModuleConfig}>
      <HomeRouter />
    </ModuleConfigProvider.ModuleConfigProvider>
  ),
  navigationItems: (roles)=>[...attachNavigation]
});

export default HomeModule;
