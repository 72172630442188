import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-image: url("${ ({ img }) => img }");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  & > .logo {
    position: absolute;
    top: 28px;
    left: 36px;
  }
  
  & > .sentence {
    position: absolute;
    top: 30px;
    right: 51.36px
  }
  
  & > .form {
    width: 370px;
    margin-right: 206px;
    padding: 49px 35px 57px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.2);
    border-radius: 10.3944px;
    background: $colorWhite;
  }
`;
