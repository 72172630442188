import { not, isNil } from 'ramda';
import { loginRequestAPI } from '../constants/msalConfig';
import handleSessionData from './handleSessionData';

/**
 * is listening for the authentication to be completed
 */
const handleRedirectAD = (msalInstance, AppSession, stateAppLoader, setStateAppLoader, exitSession) => {

  const stopAppLoader = () => (stateAppLoader && setStateAppLoader(false));

  msalInstance.handleRedirectPromise()
  .then((response) => {
    const authSuccessfully = not(isNil(response?.accessToken));
    if (authSuccessfully) {
      // get access token for the requests to the apis (only api scope)
      msalInstance.acquireTokenSilent({
        ...loginRequestAPI,
        account: msalInstance.getAllAccounts()[0]
      })
      .then(_response => {
        const { accessToken } = _response;
        handleSessionData('user', { accessToken }, AppSession, exitSession);
        stopAppLoader();
      })
      .catch((error) => {
        console.trace(error);
        stopAppLoader();
      })
    } else {
      stopAppLoader();
    }
  })
  .catch((error) => {
    console.trace(error);
    stopAppLoader();
  })
}

export default handleRedirectAD;