export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_AUTORITY_TENANT}/`,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_AD_REDIRECT_LOGOUT_URL
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    'openid',
    'profile',
    'User.Read',
    process.env.REACT_APP_AD_SCOPE_API_NPAC
  ],
};

export const loginRequestAPI = {
  scopes: [
    process.env.REACT_APP_AD_SCOPE_API_NPAC
  ]
};