import useSWR from 'swr';
import { isNil, prop } from 'ramda';
import SharedModule from 'modules/shared';

const { ServiceFetcher } = SharedModule.libs;

const useRoles = () => {
  const { data, error } = useSWR('/security/my-roles', ServiceFetcher, { revalidateOnFocus: false });

  return {
    roles: isNil(prop('roles', data)) ? null : data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useRoles;