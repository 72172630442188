import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import {
  Modal, ModalBody, ModalFooter, Button,
} from '@prima-afp/prima-ui-elements/dist/core';
import { DescriptionText, TitleText } from './styles';
import AlertIcon from '../../assets/images/alert.svg';

const PopupRolesNotAvailable = ({ show, onConfirm }) => (
  <Modal
    show={show}
    centered
    borderRadius="1rem"
    padding="1.5rem"
  >
    <ModalBody>
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-1">
        <ReactSVG src={AlertIcon} alt="icono de alerta" />
        <TitleText>Nuestro sistema tuvo un problema</TitleText>
        <DescriptionText>Lo sentimos, intenté ingresar nuevamente en unos minutos.</DescriptionText>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="tw-flex tw-w-full tw-justify-center">
        <Button theme="orange" onClick={onConfirm}>Entendido</Button>
      </div>
    </ModalFooter>
  </Modal>
);

PopupRolesNotAvailable.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PopupRolesNotAvailable;
