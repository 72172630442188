import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './modules/shared/constants/msalInstance'
import './index.scss';
import '@prima-afp/prima-tailwind-utils/dist/tailwind-customized.css';
import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App msalInstance={msalInstance}/>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
