import React from 'react';
import { Link } from 'react-router-dom';

import asesoria from 'modules/Home/assets/images/asesoria.svg';
import piggyBank from 'modules/Home/assets/images/piggyBank.svg';
import iliustracion from 'modules/Home/assets/images/iliustracion.svg';
import misTramites from 'modules/Home/assets/images/misTramites.svg';

import SharedModule from 'modules/shared';
import ModuleConfigProvider from 'modules/shared/providers/ModuleConfigProvider';
import {
  PortraitImage,
  CardGrid,
  ButtonCard1,
  ButtonCard2,
  ButtonCard3,
  ResponsiveBlock1,
  Content,
  Image,
  Title,
  Text,
  TitleOrange,
} from './style';

import CardTitleGray from '../../components/Card/CardTitleGray';
import WhiteCard from '../../components/Card/WhiteCard';

const DashboardClient = () => {
  const { useMe } = SharedModule.hooks;
  const { advisor } = useMe();

  const proceduresConfig = ModuleConfigProvider.useModuleConfig();

  const textDashboardClient = {
    greeting: `Hola ${advisor.names}`,
    contentGreeting: 'Gestiona los trámites  ',
    hightlighGreeting: 'de forma rápida y fácil',
    textCard1: 'Consulta y gestiona Asesoría 95.5% ',
    textCard2: 'Consulta y gestiona todo los demás trámites',
    textCard3: 'Cuentas voluntarias de libre disponibilidad',
  };

  const linkDashboardClient = {
    linkButton1: '/proceso95-5',
    linkButton2: '/tramites/detalles-tramites/',
    linkButton3: '/apvsf',
  };

  return (
    <WhiteCard>
      <PortraitImage>
        <img src={iliustracion} />
      </PortraitImage>

      <CardTitleGray
        user={advisor.names}
        text={textDashboardClient.contentGreeting}
        text2={textDashboardClient.hightlighGreeting}
        hideOnMobile
      />

      <TitleOrange>
        <span className="responsiveUser">
          ¡Hola !
          {advisor.names}
        </span>
        <span className="responsiveGreeting">
          {textDashboardClient.responsiveGreeting}
        </span>
      </TitleOrange>

      <CardGrid noGrid={!proceduresConfig.activate955}>
        {proceduresConfig.activate955 && (
        <ButtonCard1>
          <Link to={linkDashboardClient.linkButton1}>
            <Content>
              <Image>
                <img src={asesoria} alt="" />
              </Image>
              <Title>{textDashboardClient.titleCard1}</Title>
              <ResponsiveBlock1>
                <Text>{textDashboardClient.textCard1}</Text>
              </ResponsiveBlock1>
            </Content>
          </Link>
        </ButtonCard1>
        )}

        <ButtonCard2>
          <Link to={linkDashboardClient.linkButton2}>
            <Content>
              <Image>
                <img src={misTramites} alt="" />
              </Image>
              <Title>{textDashboardClient.titleCard2}</Title>
              <ResponsiveBlock1>
                <Text>{textDashboardClient.textCard2}</Text>
              </ResponsiveBlock1>
            </Content>
          </Link>
        </ButtonCard2>

        {proceduresConfig.apvsf && (
        <ButtonCard3>
          <Link to={linkDashboardClient.linkButton3}>
            <Content>
              <Image>
                <img src={piggyBank} alt="" />
              </Image>
              <Title>{textDashboardClient.titleCard3}</Title>
              <ResponsiveBlock1>
                <Text>{textDashboardClient.textCard3}</Text>
              </ResponsiveBlock1>
            </Content>
          </Link>
        </ButtonCard3>
        )}
      </CardGrid>
    </WhiteCard>
  );
};

export default DashboardClient;
