import React from 'react';
import { StageOneModule } from '@prima-afp/module-pac-retirement-955/module';
import Procedures from '@prima-afp/pac-digital-procedures/module/modules/procedures';
import {GoalsAccountModule} from '@prima-afp/module-pac-funds/dist/fundsModule'
import { AppModule, HomeModule, ActiveDirectoryAuthModule } from '../modules';
import { HomeModuleConfig } from './moduleConfigurations';
import { useGlobalAppContext, GlobalAppProvider } from '../context/GlobalAppContext'

const { ApplicationRouter } = AppModule.router;

const NewPacRouter = (props) => {

  const { msalInstance } = props;

  const addGlobalConfig = (config) => ({
    ...config,
    useGlobalAppContext
  });

  return (
    <GlobalAppProvider msalInstance={msalInstance}>
      <ApplicationRouter
        publicModules={[
          ActiveDirectoryAuthModule(addGlobalConfig({})),
        ]}
        privateModules={[
          HomeModule({ config: HomeModuleConfig }),
          StageOneModule(addGlobalConfig({})),
          Procedures.moduleRouter,
          GoalsAccountModule()
        ]}
        config={addGlobalConfig({})}
      />
    </GlobalAppProvider>
  )
};

export default NewPacRouter;
