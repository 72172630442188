import React from 'react';
import Image from '../assets/images/imageLogin.svg';
import logo from '../assets/images/primaWhite.svg';
import sentence from '../assets/images/ayer-hoy-siempre.svg';
import { Wrapper } from './styles';

const LoginWrapper = ({ children }) => (
  <Wrapper
    img={Image}
  >
    <img className="logo" src={logo} alt="logo_prima" />
    <img className="sentence" src={sentence} alt="sentence" />
    { children }
  </Wrapper>
);

export default LoginWrapper;
