import styled from 'styled-components';
import { Text } from '@prima-afp/prima-ui-elements/dist/core';

export const TitleText = styled(Text)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
`;

export const DescriptionText = styled(Text)`
  font-family: Calibri, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;
