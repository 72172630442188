import React, { createContext, useContext } from 'react';

const ModuleConfigContext = createContext();

function ModuleConfigProvider({ config = {}, defaultConfig = {}, children }) {
  const configData = {
    ...defaultConfig,
    ...config,
  };
  return <ModuleConfigContext.Provider value={configData}>{children}</ModuleConfigContext.Provider>;
}

function useModuleConfig() {
  const context = useContext(ModuleConfigContext);
  if (!context) {
    throw new Error('Module config Config provider is required');
  }
  return context;
}

export default {
  ModuleConfigProvider,
  useModuleConfig,
};
