import React, { useState } from "react";
import AppSession from '../modules/shared/libs/AppSession';

const GlobalAppContext = React.createContext();

export function GlobalAppProvider(props) {

  const { msalInstance } = props;
  const [ stateAppLoader, setStateAppLoader ] = useState(true);
  const [ exitSession, setExitSession ] = useState(false);
  const [ hasRolesInStorage, setHasRolesInStorage ] = useState(false);

  const appDataShared = {
    msalInstance,
    AppSession,
    stateAppLoader,
    setStateAppLoader,
    exitSession,
    setExitSession,
    hasRolesInStorage,
    setHasRolesInStorage
  };

  return <GlobalAppContext.Provider value={appDataShared} {...props} />;
}

export function useGlobalAppContext() {
  const context = React.useContext(GlobalAppContext);
  if (!context) {
    throw new Error("GlobalAppContext provider is required");
  }
  return context;
}
