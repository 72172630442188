import React from 'react';
import LoginWrapper from '../components/LoginWrapper';
import LoginFormWrapper from '../components/LoginFormWrapper';
import LoginForm from '../components/LoginForm';

const LoginPage = (props) => {
    const { config } = props;

    return (
      <LoginWrapper>
        <LoginFormWrapper>
          <LoginForm config={config} />
        </LoginFormWrapper>
      </LoginWrapper>
    )
};

export default LoginPage;
