import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { Switch, Route, Redirect } from 'react-router-dom';

const PublicRouter = ({ publicModules = [] }) => (
  <Switch>
    { publicModules.map(
      (module) => (
        <Route
          path={prop('pathBase', module)}
          key={prop('pathBase', module)}
          render={prop('componentRouter', module)}
        />
      ),
    )}
    <Redirect from="*" to="/login" />
  </Switch>
);

PublicRouter.propTypes = {
  publicModules: PropTypes.array,
};

export default PublicRouter;
