import './app.scss';
import React, { useEffect } from 'react';

import { EventBusHandler } from '@prima-afp/common-libs/dist/libs/utils';
import { PAC_EVENT_BUS_CHANNEL } from './modules/shared/libs/PacEventBus';

// UI Element
import GlobalStyle from '@prima-afp/prima-ui-elements/dist/utils/theme/globalStyle';
import { getFromSessionStorage } from '@prima-afp/prima-ui-elements/dist/utils/theme/sessionStorage';
import { useTheme } from '@prima-afp/prima-ui-elements/dist/utils/theme/useTheme';
import '@prima-afp/prima-tailwind-utils/dist/tailwind-customized.css';
import NewPacRouter from './router/NewPacRouter';

EventBusHandler.createEventBusChannel(PAC_EVENT_BUS_CHANNEL);

const App = (props) => {
  const { msalInstance } = props;
  const { themeLoaded, setThemeMode } = useTheme();

  useEffect(() => {
    const alltheme = getFromSessionStorage('all-themes');
    setThemeMode(alltheme.themes.green);
  }, [themeLoaded]);

  return (
    <GlobalStyle>
        <NewPacRouter msalInstance={msalInstance} />
    </GlobalStyle>
  );
};

export default App;
