import React from 'react';
import { Progress } from '@prima-afp/prima-ui-elements/dist/core';
import styled from 'styled-components';

const LoaderWrapperStyled = styled.div`
    height: 100vh;
    width: 100%;
`;

const AppLoader = () => {
    return (
        <LoaderWrapperStyled>
            <Progress theme='green' />
        </LoaderWrapperStyled>
    );
};

export default AppLoader;