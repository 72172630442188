import React from 'react';
import PropTypes from 'prop-types';

import { AppRouter } from '@prima-afp/prima-business-elements/dist/businessElements/application';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import AppLoader from '../../ActiveDirectoryAuth/components/AppLoader'
import handleRedirectAD from '../../ActiveDirectoryAuth/utils/handleRedirectAD'


const ApplicationRouter = (props) => {
  const { privateModules = [], publicModules = [], config = {} } = props;
  const { useGlobalAppContext }  = config;
  const {
    msalInstance,
    AppSession,
    stateAppLoader,
    setStateAppLoader,
    exitSession,
    setHasRolesInStorage
  } = useGlobalAppContext();

  setHasRolesInStorage(AppSession.getRoles()?.roles?.length > 0);

  handleRedirectAD(msalInstance, AppSession, stateAppLoader, setStateAppLoader, exitSession);

  const existSession = () => (msalInstance.getAllAccounts()?.[0]);

  const addConfig = (_config) => ({
    ..._config,
    useGlobalAppContext
  })

  return (
    <>
      {
        stateAppLoader
        ? <AppLoader/>
        : <AppRouter
            privateRouter = { <PrivateRouter  privateModules = { privateModules } config = { addConfig({}) } /> }
            publicRouter  = { <PublicRouter   publicModules = { publicModules }   config = { addConfig({}) } /> }
            sessionValidator = { existSession }
          />
      }
    </>
  );
};

ApplicationRouter.propTypes = {
  privateModules: PropTypes.array,
  publicModules: PropTypes.array,
  config: PropTypes.object,
};

export default ApplicationRouter;
