import { loginRequest } from '../constants/msalConfig'
import handleAcquireTokenSilent from './handleAcquireTokenSilent';

/**
 * this function redirects to authentication
 */
const loginRedirect = (msalInstance) => {
  if(msalInstance.getAllAccounts()?.length === 0){
    msalInstance.loginRedirect(loginRequest)
  } else {
    handleAcquireTokenSilent(msalInstance)
  }
}

export default loginRedirect;
