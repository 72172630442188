const handleSessionData = (key, data, AppSession, exitSession) => {
  if(!exitSession){
    switch (key) {
      case 'user':
        AppSession.create(data);
        break;
      case 'roles':
        AppSession.createRoles(data);
        break;
      default:
        break;
    }
  } else {
    return;
  }
};

export default handleSessionData;