import styled from 'styled-components';
import $ from 'global/styles';
import { media } from '@prima-afp/prima-ui-elements/dist/utils/media/styledConfig';

const BodyStyled = styled.div`
  display: grid;
  grid-template-columns: 0 1fr;
  grid-template-rows: auto;
  height: 100vh;
  background: #f7f7f8;
  ${
  media.md`
        grid-template-columns: 228px 1fr;
      `
}
`;

const SidebarStyled = styled.div`
  background-color: ${$.gris};
  height: calc(100vh - 60px);
`;

const ContentStyled = styled.div`
  padding-left: 55px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 33px;
  overflow-y: scroll;
  height: 100%
`;

const PageStyled = styled.div`
  // overflow-x: hidden;
`;

export {
  BodyStyled,
  SidebarStyled,
  ContentStyled,
  PageStyled,
};
