const allColors = {
  mainColor: '#CFCFCF',
  mainColor2: '#FF4F00',
  // verde: '#00A499',
  verde: '#00AE99',
  gris: '#696158',
  grisClaro: '#696158',
  grisDisabled: '#C4C4C4',
  blanco: '#FFFFFF',
  disabled: '#B9B9B9',
  colorBlack: '#000000',
  colorGrayBorder: '#E8E8E8',
  colorGrayComponents: '#F9F9FB',
  colorGrayHover: '#F4F4F4',
  colorGrayDisabled: '#BBBBBB',
  colorGrayCard: '#F7F7F8',
  colorGrayText: '#696158',
  colorGrayComment: '#9F9F9F',
  colorGrayCardDashed: '#D7D9D9',
  colorGrayLineDashed: '#BABABA',
  colorGrayLight: '#D6D6D6',
  colorGrayStep: '#A29D98',
  colorGreen: '#00A499',
  colorGreenBalance: '#00AE99',
  colorGreenHover: '#059F8D',
  colorGreenSelectCard: '#EEFBF9',
  colorOrangeHover: '#EA4800',
  colorOrangeMain: '#FF4F00',
  colorOrangeMainActive: '#F04C02',
  colorRedError: '#FF4B4B',
  colorTooltip: '#C4C4C4',
  colorWhiteBase: '#FFFFFF',
  colorPlaceholder: '#9D9994',
  colorPinkCardBackground: '#ffe5d9',
};

export default allColors;
