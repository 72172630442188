import React from 'react';
import {
  BodyStyled, ContentStyled, PageStyled,
} from './styles';
import DashboardLateralMenu from '../components/DashboardLateralMenu';

const MainDashboardLayout = (props) => {
  const { children, navItems = [], config = {} } = props;
  const { useGlobalAppContext }  = config;
  const navigationItems = typeof navItems === 'function' ? navItems() : navItems;

  const addConfig = (_config) => ({
    ..._config,
    useGlobalAppContext
  });

  return (
    <BodyStyled>
      <DashboardLateralMenu navItems={navigationItems} config={addConfig({})} />
      <ContentStyled>
        <PageStyled>
          {children}
        </PageStyled>
      </ContentStyled>
    </BodyStyled>
  );
};

export default MainDashboardLayout;
