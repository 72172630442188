import { includes } from 'ramda';
import AppSession from '../../shared/libs/AppSession';
import { loginRequest, loginRequestAPI } from '../constants/msalConfig';
import handleSessionData from './handleSessionData';

const handleAcquireTokenSilent = (msalInstance) => {
  if(msalInstance.getAllAccounts()?.length === 0){
    msalInstance.loginRedirect(loginRequest)
  } else {
    // get access token for the requests to the apis (only api scope)
    msalInstance.acquireTokenSilent({
      ...loginRequestAPI,
      account: msalInstance.getAllAccounts()[0]
    })
    .then(response => {
      const { accessToken } = response;
      handleSessionData('user', { accessToken }, AppSession, false);
      if (includes('/inicio', window.location.href)) {
        const hasAccessTokenInStorage = !!AppSession.get()?.accessToken;
        hasAccessTokenInStorage && window.location.reload();
      } else {
        window.location.replace('/inicio');
      }
    })
    .catch((error) => {
      console.trace(error);
    })
  }
};

export default handleAcquireTokenSilent;