import useSWR from 'swr';
import { prop } from 'ramda';

import libs from 'modules/shared/libs';

const { ServiceFetcher } = libs;

const useMe = () => {
  const { data, error } = useSWR('/v1/me', ServiceFetcher, { revalidateOnFocus: false });

  const advisor = {
    friendlyName: data ? `${prop('names', data)} ${prop('lastnames', data)}` : 'No disponible',
    ...data,
  };

  return {
    advisor,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useMe;
